import React from 'react';
import Chart from "react-google-charts";
import $ from 'jquery';
import WorldwideIcon from '../assets/worldwide.png';

export default class WorldMap extends React.Component {

  state = {
    selectedCountry: null,
  }

  findColor = (recovered) => {
    if (this.state.selectedCountry) return recovered

    if (recovered < 100) return 1
    else if (recovered < 1000) return 2
    else if (recovered < 10000) return 3
    else if (recovered < 100000) return 4
    else return 5
  }

  formatRawDataForMap = () => {
    let data = this.props.data[ (this.state.selectedCountry && this.state.selectedCountry.name) || 'world'];
    if (!data) return []

    let formatted = (data.list)
    .filter((item) => item.recovered !== -1)
    .map((item) => 
      [
        item.state || item.country, 
        this.findColor(item.recovered),
        `<div class="geochart-tooltip-recovered-title">${item.recovered}</div> 
         <div class="geochart-tooltip-recovered-subtitle">people have recovered</div>`
      ]
    )
    formatted.unshift(['Area', 'Color', { role: "tooltip", type: "string", p: { html: true } }]);
    return formatted;
  }

  countries = [
    {name: 'unitedstates', code: 'US'},
    {name: 'canada', code: 'CA'},
    {name: 'australia', code: 'AU'},
    {name: 'italy', code: 'IT'},
  ]

  render() {
    return (
	    <div id="geochart">
        <div id="mapTitleWrapper">
          <div id="mapTitle">Recovered</div>
          <div id="mapSubTitle">from COVID-19</div>
        </div>

        {!this.props.data && 
          <div id="mapError">Sorry! We are having difficulty accessing the data source on this network. Please try on a different network and contact us if the problem persists.</div>}
        {this.props.data &&
          <div id="geoChartMap">
    	      <Chart
    	        chartType="GeoChart"
    	        data={this.formatRawDataForMap()}
    	        width='100%'
    	        height={$(window).height() * 0.35}
    	        options={{
                region: this.state.selectedCountry && this.state.selectedCountry.code,
                resolution: this.state.selectedCountry ? 'provinces' : 'countries',
    	          sizeAxis: { minValue: 0, maxValue: 100 },
    	          colorAxis: { colors: [  '#d3fee1', '#64e493', '#24c55f']},
    	          backgroundColor: 'white',
    	          defaultColor: '#ffdac1',
    	          legend: 'none',
                tooltip: { isHtml: true, trigger: "visible" }
    	        }}
    	        rootProps={{ 'data-testid': '1' }}
    	      />

            <div class="mapCountrySelectorWrapper">
              <img 
                src={WorldwideIcon} alt="" 
                className={`mapCountrySelectorFlag ${!this.state.selectedCountry ? 'selected' : null}`}
                onClick={() => this.setState({selectedCountry: null})}
              />

              {this.countries.map((country, index) =>
                <div 
                  key={index}
                  className={`mapCountrySelectorFlag ${this.state.selectedCountry && this.state.selectedCountry.code === country.code ? 'selected' : null}`}
                  style={{'backgroundImage': `url(https://hatscripts.github.io/circle-flags/flags/${country.code.toLowerCase()}.svg)`}} 
                  onClick={() => this.setState({selectedCountry: {name: country.name, code: country.code}})}
                />
              )}
            </div>
          </div>
        }
	      <div id="stayPositiveHeading">
	        <div id="stayText">
	          <span>stay home</span>
	          <span>stay safe</span>
	          <span>stay positive</span>
	        </div>
	      </div>
	    </div>
    )
  }
}
