import React from 'react';
import BorderWave from '../assets/borderWave.svg';
import {
  TwitterShareButton,
  TwitterIcon,

  FacebookShareButton,
  FacebookIcon,

  // FacebookMessengerShareButton,
  // FacebookMessengerIcon,

  WhatsappShareButton,
  WhatsappIcon,

  TelegramShareButton,
  TelegramIcon,

  LinkedinShareButton,
  LinkedinIcon,

  RedditShareButton,
  RedditIcon,

  EmailShareButton,
  EmailIcon
} from 'react-share';

export default class ShareCard extends React.Component {
  render() {
  	let shareUrl = 'www.thehappyarc.com';
	let title = 'Check out this stress-free COVID-19 tracker and read some happy news!';
	let hashtags = "#thehappyarc";
	let buttonSize = 42;
    return (
    	<div className="card-smallest"> 
	        <div className="shareWrapper">
		        
		        <img src={BorderWave} alt="" className="borderWave"/>

		        <div className="shareButtons">
			        <TwitterShareButton url={shareUrl} title={title} hashtags={["thehappyarc", "covid19"]} via={'thehappyarc'} related={['thehappyarc']}>
		            	<TwitterIcon size={buttonSize} round />
		          	</TwitterShareButton>

			        <FacebookShareButton url={shareUrl} quote={title} hashtag={hashtags}>
			            <FacebookIcon size={buttonSize} round />
			        </FacebookShareButton>
			        
			        <WhatsappShareButton url={shareUrl} title={title}>
		            	<WhatsappIcon size={buttonSize} round />
		          	</WhatsappShareButton>

		          	<TelegramShareButton url={shareUrl} title={title}>
		            	<TelegramIcon size={buttonSize} round />
		          	</TelegramShareButton>

		          	<LinkedinShareButton url={shareUrl} title='The Happy Arc' summary={title}>
			            <LinkedinIcon size={buttonSize} round />
			        </LinkedinShareButton>

			        <RedditShareButton
			            url={shareUrl}
			            title={title}
			            windowWidth={660}
			            windowHeight={460}>
			            <RedditIcon size={buttonSize} round />
			        </RedditShareButton>

			        <EmailShareButton
			            url={shareUrl}
			            subject='The Happy Arc'
			            body={title}>
			            <EmailIcon size={buttonSize} round />
			        </EmailShareButton>
		        </div>

		        <img src={BorderWave} alt="" className="borderWaveFlipped"/>
		    </div>   
      	</div>
    )
  }
}

// <FacebookMessengerShareButton url={shareUrl} appId="164764721579329">
// 			            <FacebookMessengerIcon size={buttonSize} round />
// 			        </FacebookMessengerShareButton>