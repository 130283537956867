import React from 'react';
import Chart from "react-google-charts";
import $ from 'jquery';
import { Dropdown, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import axios from 'axios';

export default class LineGraphData extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedCountry: 'Worldwide',
      selectedTimePeriod: $(window).width() < 800 ? "30" : "-1",
      searchKeyword: '',
      worldwideData: null,
      countryDataTimeline: null,
      countryData: null
    };
  }

  componentDidMount = async() => {

    try {
      let totalResponse = await axios('https://corona-api.com/timeline');
      this.setState({'worldwideData' : totalResponse.data.data})

      let countriesResponse = await axios('https://corona-api.com/countries?include=timeline');
      
      if (countriesResponse.data.data.length > 0) {
        this.setState({'countryDataTimeline': countriesResponse.data.data})
        this.setState({'countryData': this.formatRawDataForTable(countriesResponse.data.data)})
      }
    } catch (error) {
      this.setState({'worldwideData' : null})
      this.setState({'countryDataTimeline': null})
      this.setState({'countryData': null})
    }
  }

  formatRawDataForTable = (data) => {
    let formatted = (data).map((item) => {
      return {
        name: item.name,
        confirmed: item.latest_data.confirmed,
        recovered: item.latest_data.recovered,
        percentageRecovered: item.latest_data.confirmed > 0 ? item.latest_data.recovered / item.latest_data.confirmed * 100 : 0
      }
    })
    formatted = formatted.sort((elem1, elem2) => {
      return elem2.recovered - elem1.recovered || elem2.confirmed - elem1.confirmed;
    })
    
    let latestCompleteWorldWideData = this.state.worldwideData[0];
    if (latestCompleteWorldWideData) {
      let worldwideConfirmed = latestCompleteWorldWideData.confirmed;
      let worldwideRecovered = latestCompleteWorldWideData.recovered;
      let worldWidePercentageRecovered = worldwideConfirmed > 0 ? worldwideRecovered / worldwideConfirmed * 100 : 0;
      formatted.unshift({
        name: 'Worldwide',
        confirmed: worldwideConfirmed,
        recovered: worldwideRecovered,
        percentageRecovered: worldWidePercentageRecovered
      })
    }

    return formatted;
  }

  preformatWorldData = () => {
  	let formatted = (this.state.worldwideData)
    .filter((item) => {return !item.is_in_progress})
    .map((item, index) => {
      return [
        new Date(item.updated_at),
        item.new_recovered,
        item.new_confirmed,
      ]
    })
    .reverse();
    formatted.unshift(['Time', 'New Recovered Cases Reported', 'New Confirmed Cases Reported']);
    return formatted;
  }

  filterByCountry = () => {
  	if (this.state.selectedCountry === 'Worldwide') return this.preformatWorldData();
  	let selectedCountryData = this.state.countryDataTimeline.find((elem) => elem.name === this.state.selectedCountry);
  	let formatted;
  	if (!selectedCountryData.timeline) formatted = []
  	else {
  		formatted = (selectedCountryData.timeline)
	    .filter((item) => {return !item.is_in_progress})
	    .map((item, index) => {
	      return [
	        new Date(item.updated_at),
	        item.new_recovered,
	        item.new_confirmed,
	      ]
	    })
	    .reverse();
	}
    formatted.unshift(['Time', 'New Recovered Cases Reported', 'New Confirmed Cases Reported']);

  	return formatted;
  }

  filterByTimePeriod = (data) => {
  	if (this.state.selectedTimePeriod === '-1') return data;

  	var startDate = new Date();
	startDate.setDate(startDate.getDate() - Number(this.state.selectedTimePeriod));

  	return data.filter((elem, index) => index === 0 || elem[0] >= startDate);
  }

  searchByCountry = (val) => {
    this.setState({'searchKeyword' : $(val.target).val().trim().toLowerCase()});
  }

  render() {

  	if (!this.state.worldwideData || !this.state.countryData || !this.state.countryDataTimeline) {
  		return null;
  		return (
	  		<div className="card-medium">
	    		<div className="cardTitle"> New Cases Reported Per Day</div>
	    		<div className="lineChartWrapper">
	    			<p>Data unavailable</p>
				</div>
	    	</div>
	  	)
  	}
    return (
    	<div className="card-medium">
    		<div className="cardTitle"> New Cases Reported Per Day</div>
    		<div className="lineChartWrapper">
    			<div className="lineChartToggleWrapper">
    				<Dropdown>
					  <Dropdown.Toggle variant="success" id="dropdown-basic">
					    {this.state.selectedCountry}
					  </Dropdown.Toggle>

					  <Dropdown.Menu>
					  	<input id="countrySearchLine" placeholder="search" onChange={this.searchByCountry}/>
					  	{this.state.countryData
					  		.filter((elem) => ((elem.name).toLowerCase()).includes(this.state.searchKeyword))
					  		.map((elem,index) => 
					  			<Dropdown.Item onClick={() => this.setState({selectedCountry : elem.name})}>{elem.name}</Dropdown.Item> 
				  			)
				  		}
					  </Dropdown.Menu>
					</Dropdown>
	    			<ToggleButtonGroup name="timePeriod" value={this.state.selectedTimePeriod} onChange={(val) => this.setState({'selectedTimePeriod': val})}>
					  <ToggleButton name="timePeriod" value="7"> 7 days </ToggleButton>
					  <ToggleButton name="timePeriod" value="30"> 30 days </ToggleButton>
					  <ToggleButton name="timePeriod" value="-1"> all time </ToggleButton>
					</ToggleButtonGroup>
				</div>
				{ this.filterByTimePeriod(this.filterByCountry()).length === 1 && 
	              <p style={{height: 350}}>Sorry! It does not seem like we have enough data on this country.</p>
	            }
	            { this.filterByTimePeriod(this.filterByCountry()).length > 1 && 
	    			<Chart
					  height={'350px'}
					  chartType="Line"
					  loader={<div>Loading Chart</div>}
					  data={this.filterByTimePeriod(this.filterByCountry())}
					  options={{
					  	colors: ['#68c73d','#f7847e'],
					    hAxis: {
					      title: 'Time',
					    },
					    vAxis: {
					      title: 'Number of Cases',
					    },
					    legend: {position: 'none'}
					  }}
					  rootProps={{ 'data-testid': '1' }}
					/>
				}
			</div>
			<div className="lineChartLegend"> 
				<div id="lineChartLegendItemConfirmed" className="lineChartLegendItem"> 
					<div className="lineChartLegendMarker"/> 
					daily confirmed cases 
				</div>
				<div id="lineChartLegendItemRecovered" className="lineChartLegendItem"> 
					<div className="lineChartLegendMarker"/> 
					daily recovered cases 
				</div>
			</div>
    	</div>
    )
  }
}