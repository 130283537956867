import React from 'react';
import $ from 'jquery';
import { withCookies, Cookies } from 'react-cookie';
import Rainbow from '../assets/rainbow2.svg';
import { ProgressBar } from 'react-bootstrap';
import { instanceOf } from 'prop-types';

class DataTableCard extends React.Component {

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const { cookies } = props;

    let currentPins = cookies.get('pinnedCountriesDataTable');
    let dataTableSortColumn = cookies.get('dataTableSortColumn');

    this.state = {
     searchKeyword: '',
     pinnedCountriesDataTable: currentPins || ['Worldwide'], 
     moreCountriesLoaded: false,
     dataTableSortColumn: dataTableSortColumn || 'recovered'
    };
  }

  selectCountry = (name) => {
    let currentPins = this.state.pinnedCountriesDataTable;

    var index = currentPins.indexOf(name);
    if (index > -1) {
        currentPins.splice(index, 1);
    } else {
      currentPins.push(name)
    }

    this.setState({'pinnedCountriesDataTable': currentPins})

    const { cookies } = this.props;
    cookies.set('pinnedCountriesDataTable', currentPins);
  }

  setDataTableSorting = (column) => {
    this.setState({'dataTableSortColumn': column})
    const { cookies } = this.props;
    cookies.set('dataTableSortColumn', column);
  }

  searchByCountry = () => {
    this.setState({'searchKeyword' : $('#countrySearchTable').val().trim().toLowerCase()});
  }

  getName = (area) => {
  	return area.state ? area.state : area.country
  }

  renderRows = (showPinned) => {
  	return (
  	  this.props.allTotals
      .filter((value) => {
        let isPinned = (this.state.pinnedCountriesDataTable).includes(this.getName(value));
        let searchedFor = ((this.getName(value)).toLowerCase()).includes(this.state.searchKeyword);
        if (showPinned) return isPinned && searchedFor
    	else return !isPinned && searchedFor
      })
      .sort((value1, value2) => {
        let chosenColumn = this.state.dataTableSortColumn;
        if (chosenColumn === 'percentageRecovered') {
        	value1['percentageRecovered'] = value1['confirmed'] > 0 ? value1['recovered'] / value1['confirmed'] * 100 : 0;
        	value2['percentageRecovered'] = value2['confirmed'] > 0 ? value2['recovered'] / value2['confirmed'] * 100 : 0;
        }
        return value2[chosenColumn] - value1[chosenColumn]
      })
      .slice(0, showPinned || this.state.moreCountriesLoaded || this.state.searchKeyword !== '' ? undefined : Math.max(0, 9 - this.state.pinnedCountriesDataTable.length))
      .map((value, index) => {
      	let confirmed = value.confirmed;
        let recovered = value.recovered;
        let percentageRecovered = confirmed > 0 ? recovered / confirmed * 100 : 0;

        return (
          <>
          <tr key={index}>
            <td>
              <a onClick={() => this.selectCountry(this.getName(value))} className={showPinned ? "selected" : null} >
                <img src={Rainbow} alt="" className="rainbowIcon"/> 
                {this.getName(value)}
              </a>
            </td>
            <td>{recovered > 0 ? recovered.toLocaleString() : '-'}</td>
            <td>{confirmed > 0 ? confirmed.toLocaleString() : '-'}</td>
            <td> 
              <ProgressBar now={Math.round(percentageRecovered)} />
              {percentageRecovered > 0 && `${Math.round(percentageRecovered)}%`}
            </td>
          </tr>
          <tr><td className="progressBarHack" colSpan="4"><ProgressBar now={Math.round(percentageRecovered)} /></td></tr>
          </>
        )
      })
	)
  }

  render() {
  	if (!this.props.allTotals) return null
  	return (
	  	<div id="card-data" className="card"> 
	      <div className="tableWrapper"> 
	        <table>
	          <thead>
	            <tr>
	              <th><input id="countrySearchTable" placeholder={$(window).width > 600 ? "search for country" : "search"} onChange={this.searchByCountry}/></th>
	              <th 
	                onClick={() => {this.setDataTableSorting('recovered')}}
	                className={this.state.dataTableSortColumn === 'recovered' ? 'sort' : null}
	              >Recovered</th>
	              <th 
	                onClick={() => {this.setDataTableSorting('confirmed')}}
	                className={this.state.dataTableSortColumn === 'confirmed' ? 'sort' : null}
	              >Confirmed</th>
	              <th 
	                onClick={() => {this.setDataTableSorting('percentageRecovered')}}
	                className={this.state.dataTableSortColumn === 'percentageRecovered' ? 'sort' : null}
	              >Percentage</th>
	            </tr>
	          </thead>
	          <tbody>

	          	{ this.renderRows(true) }
	          	{ this.renderRows(false) }

	          </tbody> 
	        </table>
	        {!this.state.moreCountriesLoaded && this.props.allTotals.length > 0 && this.state.searchKeyword === "" &&
	          <div>
	            <button 
	              id="loadMoreCountries" 
	              onClick={()=> this.setState({'moreCountriesLoaded': true})}>
	              load more
	            </button>
	          </div>
	        }
	      </div>
	    </div>
    )
  }
}

export default withCookies(DataTableCard);