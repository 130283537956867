import React from 'react';

export default class DataErrorCard extends React.Component {
  render() {
    return (
  		<div className="card-large">
    		<div className="lineChartWrapper">
    			<div className="cardTitle"> Sorry!</div>
    			<p>We are having difficulty accessing the data source on this network</p>
    			<p>Please try on a different network and contact us if the problem persists</p>
    			<p>In the meantime, you can check out the <a href="#newsWrapper">happy news</a> below!</p>
			</div>
    	</div>
    )
  }
}