import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import news from '../assets/newsJSON';

export default class HappyNews extends React.Component {
  render() {
  	const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 800,
      autoplaySpeed: 4000,
      cssEase: "ease-in-out",
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    };
    return (
      <div id="newsWrapper">
      	<div id="newsTitle">happy news</div>
      	<Slider {...settings}>
	      	{ news.map((article,index) => 
	      		<div key={index} className="slickWrapper"> 
	      			<div className="card-news">
              <a href={article.source} target="_blank">
	      				<img src={article.image} />
	      				<div className="card-news-content">
                  <div className="card-news-title">{article.text}</div>
  	      				<div className="cardSubtitle">{article.sourceText}</div>
                </div>
                </a>
      				</div>
      			</div>
      	  	)}
      	</Slider>
      </div>
    )
  }
}
