import React from 'react';
import $ from 'jquery';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class PercentageHighlights extends React.Component {

  state = {
    numberOfSections : 0
  }

  resizeId = null;

  componentDidMount = () => {
    this.calculateNumberOfSections();

    $(window).resize(() => {
      clearTimeout(this.resizeId);
      this.resizeId = setTimeout(this.calculateNumberOfSections, 300);
    });
  }

  componentWillUnmount = () => {
    if (this.resizeId) clearTimeout(this.resizeId);
  }

  calculateNumberOfSections = () => {
    let numberOfSections = 3;
    if ($(window).width() < 400) numberOfSections = 2;
    this.setState({numberOfSections})
  }

  formatDataForPercentageHighlights = (data) => {
    let formatted = (data)
      .filter((item) => item.recovered !== -1 && item.confirmed !== -1 && !item.state)
      .sort((a,b) => b.recovered - a.recovered)
      .map((item) => {
        return {
          name: item.country,
          code: item.country_code,
          recovered: item.recovered,
          confirmed: item.confirmed,
          percentageRecovered: Math.round(item.confirmed > 0 ? item.recovered / item.confirmed * 100 : 0)
        }
      })
      .reduce(function (r, a) {
        r[a.percentageRecovered] = r[a.percentageRecovered] || [];
        r[a.percentageRecovered].push(a);
        return r;
      }, [])
      .sort((a,b) => b[0].percentageRecovered - a[0].percentageRecovered);

    return formatted
  }

  render() {

    return (
      this.formatDataForPercentageHighlights(this.props.countryTotals)
      .splice(0, this.state.numberOfSections)
      .map((percentage, index) => {
        return (
          <div className="topRecovererItem" key={index}> 
            <div> 
              <div className="topRecovererCount"> 
                {percentage && `${Math.round(percentage[0].percentageRecovered)}%`}
              </div>
              { percentage.length == 1 && <div className="topRecovererName">{percentage.length} country</div> }
              { percentage.length > 1 && <div className="topRecovererName">{percentage.length} countries</div> }
              {percentage && 
                <div className="percentageHighlightCountryFlagWrapper">
                  { percentage.map((country, index) => {
                    if (index > 6) return null
                    else if (index === 6) {
                      return (
                        <OverlayTrigger
                          key={index}
                          placement="bottom"
                          overlay={(
                            <Tooltip id="button-tooltip">
                              {percentage.map((elem,index) => {if (index > 5) return <div>{elem.name}</div>})}
                            </Tooltip>
                          )}
                        >
                          <div className="percentageHighlightCountryFlagTag"> {percentage.length - 5}+</div>
                        </OverlayTrigger>
                      )
                    } else {
                      return (
                        <OverlayTrigger
                          key={index}
                          placement="bottom"
                          overlay={(
                            <Tooltip id="button-tooltip">
                              {country.name} ({country.recovered} out of {country.confirmed} recovered)
                            </Tooltip>
                          )}
                        >
                          <div 
                            className="percentageHighlightCountryFlag" 
                            style={{'backgroundImage': `url(https://hatscripts.github.io/circle-flags/flags/${country.code.toLowerCase()}.svg)`}} 
                          />
                        </OverlayTrigger>
                      )
                    }})
                  }
                </div>
              }
            </div>
          </div>
        )
      })
    )
  }
}
