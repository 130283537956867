export default [
	{
		text: 'Winston, The First Dog To Test Positive For COVID-19, Is Now “Completely Fine” And Living His Best Pug Life', 
		source: 'https://tanksgoodnews.com/2020/05/06/winston-dog-coronavirus/', 
		sourceText: 'tanksgoodnews.com', 
		image:'https://tanksgoodnews.com/wp-content/uploads/2020/05/winston-covid-dog-.jpg'
	},
	{
		text: 'This NJ high school culinary teacher is running a food drive from his front yard to feed his students’ families', 
		source: 'https://www.cnbc.com/2020/05/02/high-school-culinary-teacher-runs-food-drive-to-feed-students.html', 
		sourceText: 'cnbc.com', 
		image:'https://image.cnbcfm.com/api/v1/image/106516176-1588353679055foodbankfinal.jpg?v=1588353828&w=1400&h=950'
	},
	{
		text: 'Air pollution in major cities has dropped by as much as 60% thanks to shelter-in-place policies', 
		source: 'https://www.upworthy.com/air-pollution-in-major-cities-has-dropped-as-much-as-60-percent', 
		sourceText: 'upworthy.com', 
		image:'https://pbs.twimg.com/media/EWRh3q5UMAAXILU?format=jpg&name=medium'
	},
	{
		text: 'Two scientists have been injected with a hopeful coronavirus vaccine in the UK’s first human trials.',
		source: 'https://metro.co.uk/2020/04/23/first-two-volunteers-injected-oxfords-coronavirus-vaccine-12602093/', 
		sourceText: 'metro.co.uk', 
		image:'https://i2.wp.com/metro.co.uk/wp-content/uploads/2020/04/PRI_149971423.jpg?quality=90&strip=all&zoom=1&resize=644%2C338&ssl=1'
	},
	{
		text: 'Taiwan reports zero new coronavirus cases for 4th day in a row, has not seen a locally transmitted case in 17 days', 
		source: 'https://www.taiwannews.com.tw/en/news/3924994', 
		sourceText: 'taiwannews.com.tw', 
		image:'https://tnimage.s3.hicloud.net.tw/photos/2020/04/29/1588141035-5ea91beb504fd.jpg'
	},
	{
		text: 'Fundraising hero and war veteran Tom Moore sent 125,000 cards for his 100th birthday', 
		source: 'https://7news.com.au/lifestyle/health-wellbeing/fundraising-hero-and-war-veteran-tom-moore-sent-125000-cards-for-his-100th-birthday-c-1006613', 
		sourceText: '7news.com.au', 
		image:'https://images.7news.com.au/publication/C-1006613/59dbe86257ec360a82465653ff6940c1216a298e-16x9-x0y0w1920h1080.png?imwidth=1024&impolicy=sevennews_v2'
	},
	{
		text: 'Coronavirus is driving down global carbon dioxide emissions to levels last seen 10 years ago, agency says', 
		source: 'https://www.washingtonpost.com/climate-environment/2020/04/30/coronavirus-is-driving-down-global-carbon-dioxide-emissions-levels-last-seen-10-years-ago-agency-says/?utm_source=reddit.com', 
		sourceText: 'washingtonpost.com', 
		image:'https://www.washingtonpost.com/wp-apps/imrs.php?src=https://arc-anglerfish-washpost-prod-washpost.s3.amazonaws.com/public/32I7A4SRSAI6TPNXIT4URTAGAU.jpg&w=1440'
	},
	{
		text: 'Oregon high school student is making clear face masks so the hearing-impaired can be safe during coronavirus crisis', 
		source: 'https://www.oregonlive.com/teamoregon/2020/04/oregon-high-school-student-is-making-clear-face-masks-so-the-hearing-impaired-can-be-safe-during-coronavirus-crisis.html', 
		sourceText: 'oregonlive.com', 
		image:'https://www.oregonlive.com/resizer/G6PxuftOhqTrEZa3G1Xo922pi_g=/1280x0/smart/arc-anglerfish-arc2-prod-advancelocal.s3.amazonaws.com/public/MKXZ66SERFFRDGFKTXU5AT67F4.jpg'
	},
	{
		text: 'Princess Sofia of Sweden Scrubs in at Hospital to Clean and Cook During Coronavirus Outbreak', 
		source: 'https://people.com/royals/princess-sofia-of-sweden-scrubs-in-at-hospital-to-clean-and-cook-during-coronavirus-outbreak/', 
		sourceText: 'people.com', 
		image:'https://imagesvc.meredithcorp.io/v3/mm/image?url=https%3A%2F%2Fstatic.onecms.io%2Fwp-content%2Fuploads%2Fsites%2F20%2F2020%2F04%2F16%2Fprincess-sofia-1.jpg'
	},
	{
		text: 'Florida animal shelter celebrates emptying a kennel for the first time ever', 
		source: 'https://www.cnn.com/2020/04/16/us/empty-animal-shelter-trnd/index.html', 
		sourceText: 'CNN.com', 
		image:'https://cdn.cnn.com/cnnnext/dam/assets/200416124127-palm-beach-animal-shelter-empty-exlarge-169.jpg'
	},
	{
		text: 'People in India can see the Himalayas for the first time in decades, as the lockdown eases air pollution', 
		source: 'https://www.cnn.com/travel/article/himalayas-visible-lockdown-india-scli-intl/index.html', 
		sourceText: 'CNN.com', 
		image:'https://dynaimage.cdn.cnn.com/cnn/q_auto,w_1100,c_fill,g_auto,h_619,ar_16:9/http%3A%2F%2Fcdn.cnn.com%2Fcnnnext%2Fdam%2Fassets%2F200409064539-01-dhauladhar-0403.jpg'
	},
	{
		text: 'Children around the world are posting handmade rainbow artwork in the windows of their homes to spread hope and cheer', 
		source: 'https://www.foxnews.com/lifestyle/coronavirus-pandemic-kids-rainbow-pictures-hope', 
		sourceText: 'foxnews.com', 
		image:'https://a57.foxnews.com/static.foxnews.com/foxnews.com/content/uploads/2020/03/1862/1048/AP20073643369388.jpg?ve=1&tl=1'
	}
]

