import React from 'react';
import RainbowTips from '../assets/tips.png';

export default class StayingPositiveTips extends React.Component {
  render() {
    return (
	<div>
	  <div id="tipsTitleWrapper">
        <div id="tipsTitle">staying positive</div>
        <div id="tipsSubTitle">during the pandemic</div>
      </div>
      <div id="tipsWrapper">
      	<div style={{'position':'relative'}}>
      		<img id="tipsWheel" alt="" src={RainbowTips}/>
      		<div id="selectionTriangle" />
  		</div>
      	<div id="tipsContentWrapper">
      		<div>stay home, keep washing your hands</div>
      		<div>video chat with friends and family</div>
      		<div>stay active with home workouts</div>
      		<div>grow some plants</div>
      		<div>read happy news</div>
      		<div id="tipsHighlighter" />
      	</div>
      </div>
  	</div>
    )
  }
}
