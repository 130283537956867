import React from 'react';
import Rainbow from '../assets/rainbow2.svg';
import KoFiMobile from '../assets/kofi_mobile_outline.png';
import KoFi from '../assets/kofi.png';

export default class Menu extends React.Component {
  render() {
    return (
      <div id="menu">
      	<a href="#top" id="menu-subtitle-happyarc" className="menu-subtitle">the happy arc</a>
      	<a href="#top" id="menu-subtitle-happyarc" className="menu-subtitle-mobile">the happy arc</a>
      	<a href="https://ko-fi.com/thehappyarc" target="_blank" className="menu-subtitle">
          <img src={KoFi} alt="" id="koFiIcon"/>
        </a>
      	<a href="https://ko-fi.com/thehappyarc" target="_blank" className="menu-subtitle-mobile">
          <img src={KoFiMobile} alt="" id="koFiMobileIcon"/>
        </a> 
      </div>
    )
  }
}

// https://ko-fi.com/thehappyarc