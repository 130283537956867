import React from 'react';
import Curve from '../assets/curve.png';
import TodaysDataCard from './todaysDataCard';
import PercentageHighlights from './percentageHighlights';
import ShareCard from './shareCard';
import DataTableCard from './dataTableCard';
import LineGraphData from './lineGraphData2';
import BarGraphData from './barGraphData2';
import DataErrorCard from './dataErrorCard';
import HappyNews from './happyNews';
import StayingPositiveTips from './stayingPositiveTips';
import Footer from './footer';
import Cookie from '../assets/cookie.png';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';

class Data extends React.Component {

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const { cookies } = props;

    let cookieConsentGiven = cookies.get('cookieConsentGiven');

    this.state = {
      cookieConsentGiven: cookieConsentGiven || false,
    };
  }

  giveCookieConsent = () => {
    this.setState({'cookieConsentGiven': true})
    const { cookies } = this.props;
    cookies.set('cookieConsentGiven', true);
  }

  getAllTotals = () => {
    let copyCountryTotals = JSON.parse(JSON.stringify(this.props.countryTotals));
    copyCountryTotals
      .unshift(
        {
          country: 'Worldwide', 
          confirmed: this.props.worldwideTotals.confirmed,
          recovered: this.props.worldwideTotals.recovered
        }
      )
    return copyCountryTotals;
  }

  render() {
    return (
      <div id="dataSectionWrapper">
        <div id="dataSectionWrapperCurve">
          <img src={Curve} alt="" id="curve"/>
          <div className="dataSectionTitle">
            {this.props.countryTotals && <div>top recovered</div> }
            {this.props.countryTotals && <div className="dataSectionSubtitle">by percentage</div> }
          </div>
          <div id="topRecovererList"> 
            
            <PercentageHighlights countryTotals={this.props.countryTotals}/>
            <div id="cookieConsent" className={this.state.cookieConsentGiven ? "dismissed" : null}>
              <img src={Cookie} alt=""/>
             <div>Have a cookie to cheer you up! We also use cookies to personalize your experience. 
                <button onClick={this.giveCookieConsent}>Dismiss</button>
             </div>
            </div>
          </div>
          <div className="dataSectionWrapperInner">
            <DataTableCard allTotals={this.getAllTotals()}/>
            <div id="secondDataRow"> 
              <LineGraphData 
                countryList={this.props.countryList}
                timelineResponse={this.props.timelineResponse}/>
              <TodaysDataCard 
                dailyRecovered = {this.props.dailyRecovered}
                dailyConfirmed = {this.props.dailyConfirmed}
                lastUpdated = {this.props.lastUpdated}/>
            </div>
            <div id="thirdDataRow"> 
              <ShareCard />
              <BarGraphData 
                countryList={this.props.countryList}
                timelineResponse={this.props.timelineResponse}/>
            </div>
            <HappyNews/>
            <StayingPositiveTips />
          </div>
        </div>      
        <Footer />
      </div>
    )
  }
}

export default withCookies(Data);