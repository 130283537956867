import React from 'react';
import BorderWave from '../assets/borderWave.svg';

export default class TodaysDataCard extends React.Component {
  render() {

    let newConfirmed = !(this.props.dailyConfirmed > 0) ? '-' : this.props.dailyConfirmed;
    let newRecovered = !(this.props.dailyRecovered > 0) ? '-' : this.props.dailyRecovered;
    let lastUpdated = this.props.lastUpdated;
  	
    return (
    	<div className="card-small"> 
        <div className="todayData">
	        <div id="todayText" className="dataSectionSubtitle">
            <div id="todayDataTitle">Today</div>
          </div>
	        <img src={BorderWave} alt="" className="borderWave"/>
	        <div className="cardContentWrapperBottom">
            <div className="todayDataItem">
		          	<div id="todayConfirmed">{newConfirmed.toLocaleString()}</div>
		          	new cases found
		        </div>
		        <div className="todayDataItem">
	          	<div id="todayRecovered">{newRecovered.toLocaleString()}</div>
	          	people recovered
        		</div>
            <div className="cardSubtitle">
              {lastUpdated && <span> last updated {(new Date(lastUpdated)).toLocaleString()} </span>}
            </div>
          </div>
        </div>
    	</div>
    )
  }
}