import React from 'react';
import '../assets/rainbow2.css';

export default class Hero extends React.Component {

  render() {
    return (
      <div id="hero">
        <div id="mainTitle"> the happy arc </div>
        <div id="rainbowGraphic">
          <div className="graphic">
            <div className="rainbow">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div id="recoveredTotalCountHero">
            {this.props.totalRecovered && this.props.totalRecovered.toLocaleString()}
            {this.props.totalRecovered && <div className="recoveredTotalCountHeroSubtitle">people have recovered</div>}
          </div>
        </div>

      </div>
    )
  }
}
