import React from 'react';
import axios from 'axios';
import $ from 'jquery';
import './App.css';
import WorldMap from './components/WorldMap';
import Hero from './components/Hero';
import Menu from './components/Menu';
import Data from './components/Data';
import { CookiesProvider } from 'react-cookie';

export default class App extends React.Component {
  state = { 
    totalRecovered: null,
    worldwideTotals: [],
    countryTotals: [],
    allTotals: [],
    countryList: [],
    timelineResponse: null,
    dailyRecovered: null,
    dailyConfirmed: null,
    lastUpdated: null
  };

  interval = null;

  adjustHeroScroll = () => {
    $("#recoveredTotalCountHero, .graphic").css("opacity", 1 - $(window).scrollTop() / 400);

    if ($(window).scrollTop() > 0) {
      $("#geoChartMap").css("opacity", '1');
    }

    if ($(window).scrollTop() > ($('#hero').height() - 60)) {
      $("#hero").css("opacity", '0');
    } else {
      $("#hero").css("opacity", '1');
    }
  }

  componentDidMount = async() => {

    try {
      let reportResponse = await axios('https://cov19.cc/report.json?v='+Math.random());
      this.setState({'totalRecovered' : reportResponse.data.regions.world.totals.recovered})
      this.setState({'worldwideTotals' : reportResponse.data.regions.world.totals})
      this.setState({'allTotals' : reportResponse.data.regions})
      this.setState({'countryTotals' : reportResponse.data.regions.world.list})

      let oldAPIresponse = await axios('https://corona-api.com/timeline');
      this.setState({'dailyRecovered' : oldAPIresponse.data.data[0].new_recovered})
      this.setState({'dailyConfirmed' : oldAPIresponse.data.data[0].new_confirmed})
      this.setState({'lastUpdated' : oldAPIresponse.data.data[0].updated_at});

      // this.setState({'dailyRecovered' : reportResponse.data.regions.world.totals.daily_recovered})
      // this.setState({'dailyConfirmed' : reportResponse.data.regions.world.totals.daily_confirmed})
      // this.setState({'lastUpdated' : reportResponse.data.last_updated});

      let countryList = reportResponse.data.regions.world.list
      .map((elem) => { 
        return { country: elem.country, state: elem.state}
      })
      .sort((a,b) => { 
        return ((b.state || b.country) > (a.state || a.country)) ? -1 : 1
      })
      countryList.unshift({ country: 'Worldwide', state: undefined })
      this.setState({'countryList' : countryList})

      let timelineResponse = await axios('https://cov19.cc/trend.json?v='+Math.random());
      this.setState({'timelineResponse' : timelineResponse.data})

    } catch (error) {
      this.setState({'totalRecovered' : null})
      this.setState({'worldwideTotals' : null})
      this.setState({'countryTotals' : null})
      this.setState({'allTotals' : null})
      this.setState({'dailyRecovered' : null})
      this.setState({'dailyConfirmed' : null})
      this.setState({'lastUpdated' : null})
      this.setState({'countryList' : null})
      this.setState({'timelineResponse' : null})
    }

    this.adjustHeroScroll();

    window.onscroll = () => {
      this.adjustHeroScroll();
    };
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
  }

  render() {
    return (
      <div id="main">
        <div id="top">
          <Hero totalRecovered={this.state.totalRecovered} />
          <div style={{'height': $(window).height()}}/>
          <CookiesProvider>
            <Data 
              worldwideTotals = {this.state.worldwideTotals} 
              countryTotals = {this.state.countryTotals} 
              countryList = {this.state.countryList}
              timelineResponse = {this.state.timelineResponse}
              dailyRecovered = {this.state.dailyRecovered}
              dailyConfirmed = {this.state.dailyConfirmed}
              lastUpdated = {this.state.lastUpdated}
            />
          </CookiesProvider>
        </div>
        <Menu totalRecovered={this.state.totalRecovered} />
          <WorldMap data={this.state.allTotals}/>
      </div>
    )
  }
}